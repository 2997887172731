.politikat-e-privatesise-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.politikat-e-privatesise-header1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
 
.politikat-e-privatesise-navbar {
  width: 1776px;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
 
.politikat-e-privatesise-left {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.politikat-e-privatesise-navlink1 {
  display: contents;
}
 
.politikat-e-privatesise-logo {
  width: var(--dl-layout-size-large);
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
 
.politikat-e-privatesise-right {
  gap: var(--dl-layout-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.politikat-e-privatesise-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
 
.politikat-e-privatesise-icon10 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
.politikat-e-privatesise-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
 
.politikat-e-privatesise-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.politikat-e-privatesise-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-layout-space-threeunits);
  justify-content: space-between;
}
 
.politikat-e-privatesise-navlink2 {
  display: contents;
}
 
.politikat-e-privatesise-image {
  width: 78px;
  height: 67px;
  text-decoration: none;
}
 
.politikat-e-privatesise-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.politikat-e-privatesise-icon12 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
.politikat-e-privatesise-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.politikat-e-privatesise-navlink3 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.politikat-e-privatesise-navlink4 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.politikat-e-privatesise-link10 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.politikat-e-privatesise-navlink5 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.politikat-e-privatesise-links1 {
  gap: var(--dl-layout-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.politikat-e-privatesise-link11 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.politikat-e-privatesise-link11:hover {
  opacity: 0.5;
}
 
.politikat-e-privatesise-link12 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.politikat-e-privatesise-link12:hover {
  opacity: 0.5;
}
 
.politikat-e-privatesise-link13 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.politikat-e-privatesise-link13:hover {
  opacity: 0.5;
}
 
.politikat-e-privatesise-link14 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.politikat-e-privatesise-link14:hover {
  opacity: 0.5;
}
 
.politikat-e-privatesise-container3 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
 
.politikat-e-privatesise-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-layout-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
 
.politikat-e-privatesise-content7 {
  align-self: stretch;
  align-items: center;
}
 
.politikat-e-privatesise-ul {
  max-width: 1000px;
  align-items: flex-start;
}
 
.politikat-e-privatesise-heading1 {
  font-size: 40px;
  align-self: flex-start;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-heading21 {
  align-self: flex-start;
  text-align: left;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-content1 {
  display: block;
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text101 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text104 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-heading22 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content2 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text106 {
  font-size: 24px;
  font-weight: 500;
}
 
.politikat-e-privatesise-text107 {
  font-size: 22px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text108 {
  font-style: normal;
}
 
.politikat-e-privatesise-text109 {
  font-style: normal;
}
 
.politikat-e-privatesise-text110 {
  font-style: normal;
}
 
.politikat-e-privatesise-text111 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text112 {
  font-style: normal;
}
 
.politikat-e-privatesise-text113 {
  font-size: 24px;
  font-weight: 500;
}
 
.politikat-e-privatesise-text114 {
  font-size: 24px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text115 {
  font-style: normal;
}
 
.politikat-e-privatesise-text116 {
  font-style: normal;
}
 
.politikat-e-privatesise-text117 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text118 {
  font-style: normal;
}
 
.politikat-e-privatesise-text119 {
  font-style: normal;
}
 
.politikat-e-privatesise-text120 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text121 {
  font-style: normal;
}
 
.politikat-e-privatesise-text122 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text123 {
  font-style: normal;
}
 
.politikat-e-privatesise-text124 {
  font-style: normal;
}
 
.politikat-e-privatesise-text125 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text126 {
  font-style: normal;
}
 
.politikat-e-privatesise-text127 {
  font-style: normal;
}
 
.politikat-e-privatesise-text128 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text129 {
  font-style: normal;
}
 
.politikat-e-privatesise-text130 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text131 {
  font-style: normal;
}
 
.politikat-e-privatesise-text132 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text133 {
  font-style: normal;
}
 
.politikat-e-privatesise-text134 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text135 {
  font-style: normal;
}
 
.politikat-e-privatesise-text136 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text137 {
  font-style: normal;
}
 
.politikat-e-privatesise-text138 {
  font-style: normal;
}
 
.politikat-e-privatesise-text139 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text140 {
  font-style: normal;
}
 
.politikat-e-privatesise-text141 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text142 {
  font-style: normal;
}
 
.politikat-e-privatesise-text143 {
  font-style: normal;
}
 
.politikat-e-privatesise-text144 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text145 {
  font-style: normal;
}
 
.politikat-e-privatesise-text146 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text147 {
  font-style: normal;
}
 
.politikat-e-privatesise-text148 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text149 {
  font-style: normal;
}
 
.politikat-e-privatesise-text150 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text151 {
  font-style: normal;
}
 
.politikat-e-privatesise-text152 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text153 {
  font-style: normal;
}
 
.politikat-e-privatesise-text154 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text155 {
  font-style: normal;
  font-weight: 500;
}
 
.politikat-e-privatesise-text156 {
  font-style: normal;
  font-weight: 500;
}
 
.politikat-e-privatesise-text157 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text158 {
  font-style: normal;
}
 
.politikat-e-privatesise-text159 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text160 {
  font-style: normal;
}
 
.politikat-e-privatesise-text161 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text162 {
  font-style: normal;
}
 
.politikat-e-privatesise-text163 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text164 {
  font-style: normal;
}
 
.politikat-e-privatesise-text165 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text166 {
  font-style: normal;
}
 
.politikat-e-privatesise-text167 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text168 {
  font-style: normal;
}
 
.politikat-e-privatesise-heading3 {
  font-family: "Poppins";
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content31 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text169 {
  font-size: 24px;
  font-weight: 600;
}
 
.politikat-e-privatesise-text170 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text171 {
  font-style: normal;
}
 
.politikat-e-privatesise-text172 {
  font-style: normal;
}
 
.politikat-e-privatesise-text173 {
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 600;
}
 
.politikat-e-privatesise-text174 {
  font-size: 20px;
  font-weight: 600;
}
 
.politikat-e-privatesise-text175 {
  font-style: normal;
}
 
.politikat-e-privatesise-text176 {
  font-style: normal;
}
 
.politikat-e-privatesise-text178 {
  font-style: normal;
}
 
.politikat-e-privatesise-text179 {
  font-style: normal;
}
 
.politikat-e-privatesise-text181 {
  font-style: normal;
}
 
.politikat-e-privatesise-text182 {
  font-style: normal;
}
 
.politikat-e-privatesise-text183 {
  font-style: normal;
}
 
.politikat-e-privatesise-content32 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text185 {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}
 
.politikat-e-privatesise-text186 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text187 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text189 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text190 {
  font-style: normal;
}
 
.politikat-e-privatesise-text192 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text193 {
  font-style: normal;
}
 
.politikat-e-privatesise-text195 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text196 {
  font-style: normal;
}
 
.politikat-e-privatesise-text198 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text199 {
  font-style: normal;
}
 
.politikat-e-privatesise-heading4 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content4 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text201 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text204 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text207 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text210 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text213 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-heading5 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content5 {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text215 {
  font-style: normal;
}
 
.politikat-e-privatesise-text216 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text217 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text218 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text219 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text220 {
  font-style: normal;
}
 
.politikat-e-privatesise-text221 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text222 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text223 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text224 {
  font-style: normal;
}
 
.politikat-e-privatesise-text225 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text226 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text227 {
  font-style: normal;
}
 
.politikat-e-privatesise-text228 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text229 {
  font-style: normal;
}
 
.politikat-e-privatesise-text230 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text231 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text232 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text233 {
  font-style: normal;
}
 
.politikat-e-privatesise-text234 {
  font-style: normal;
}
 
.politikat-e-privatesise-text235 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text236 {
  font-family: "Poppins";
  font-weight: 500;
}
 
.politikat-e-privatesise-text237 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text238 {
  font-style: normal;
}
 
.politikat-e-privatesise-text239 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text240 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text241 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text242 {
  font-style: normal;
}
 
.politikat-e-privatesise-text243 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text244 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text245 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text246 {
  font-style: normal;
}
 
.politikat-e-privatesise-text247 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text248 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text249 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text250 {
  font-style: normal;
}
 
.politikat-e-privatesise-text251 {
  font-style: normal;
}
 
.politikat-e-privatesise-text252 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text253 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text254 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text255 {
  font-style: normal;
}
 
.politikat-e-privatesise-text256 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text257 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text258 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text259 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text260 {
  font-style: normal;
}
 
.politikat-e-privatesise-text261 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text262 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text263 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text264 {
  font-style: normal;
}
 
.politikat-e-privatesise-text265 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text266 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text267 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text268 {
  font-style: normal;
}
 
.politikat-e-privatesise-text269 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text270 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text271 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text272 {
  font-style: normal;
}
 
.politikat-e-privatesise-text273 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text274 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text275 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text276 {
  font-style: normal;
}
 
.politikat-e-privatesise-text277 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text278 {
  font-style: normal;
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text279 {
  font-weight: 500;
}
 
.politikat-e-privatesise-text280 {
  font-style: normal;
}
 
.politikat-e-privatesise-text281 {
  font-style: normal;
}
 
.politikat-e-privatesise-heading61 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content61 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text283 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text284 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-heading62 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content62 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text288 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text289 {
  font-family: "Poppins";
  line-height: 1.15;
}
 
.politikat-e-privatesise-text291 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text292 {
  font-family: "Poppins";
  line-height: 1.15;
}
 
.politikat-e-privatesise-text295 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-heading63 {
  font-family: "Poppins";
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content63 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text297 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text300 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text303 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-heading64 {
  font-family: "Poppins";
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content64 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text307 {
  font-size: 22px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.politikat-e-privatesise-text308 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
 
.politikat-e-privatesise-text309 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text310 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text311 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text312 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text313 {
  font-size: 22px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.politikat-e-privatesise-text314 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text315 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text316 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text317 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text318 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text319 {
  font-size: 22px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.politikat-e-privatesise-text320 {
  font-size: 22px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.politikat-e-privatesise-text321 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text322 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text323 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text324 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text325 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text326 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text327 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text328 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text329 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text330 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text331 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text332 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text333 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text334 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text335 {
  font-size: 18px;
}
 
.politikat-e-privatesise-text336 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text337 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-heading65 {
  font-family: "Poppins";
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content65 {
  line-height: 1.15;
}
 
.politikat-e-privatesise-heading66 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content66 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text340 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text341 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text343 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-heading67 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content67 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text346 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-heading68 {
  font-family: "Poppins";
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content68 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text351 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text352 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-text354 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-text355 {
  font-family: "Poppins";
}
 
.politikat-e-privatesise-heading69 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 1.15;
  margin-bottom: var(--dl-layout-space-twounits);
}
 
.politikat-e-privatesise-content69 {
  font-size: 18px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.15;
}
 
.politikat-e-privatesise-footer {
  gap: var(--dl-layout-space-threeunits);
  color: white;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-layout-space-sixunits);
  padding-left: var(--dl-layout-space-fiveunits);
  padding-right: var(--dl-layout-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-sixunits);
  background-color: #1B1D21;
}
 
.politikat-e-privatesise-content8 {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.politikat-e-privatesise-information {
  gap: var(--dl-layout-space-unit);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.politikat-e-privatesise-heading {
  gap: var(--dl-layout-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.politikat-e-privatesise-link15 {
  display: contents;
}
 
.politikat-e-privatesise-pasted-image1 {
  width: var(--dl-layout-size-large);
  text-decoration: none;
}
 
.politikat-e-privatesise-text364 {
  font-size: 20px;
  font-family: Poppins;
  line-height: 21px;
}
 
.politikat-e-privatesise-socials {
  gap: var(--dl-layout-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.politikat-e-privatesise-link16 {
  display: contents;
}
 
.politikat-e-privatesise-social1 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.politikat-e-privatesise-social1:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.politikat-e-privatesise-icon14 {
  width: 18px;
  height: 18px;
}
 
.politikat-e-privatesise-link17 {
  display: contents;
}
 
.politikat-e-privatesise-social2 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.politikat-e-privatesise-social2:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.politikat-e-privatesise-icon18 {
  width: 18px;
  height: 18px;
}
 
.politikat-e-privatesise-link18 {
  display: contents;
}
 
.politikat-e-privatesise-social3 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.politikat-e-privatesise-social3:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.politikat-e-privatesise-icon20 {
  width: 18px;
  height: 18px;
}
 
.politikat-e-privatesise-link19 {
  display: contents;
}
 
.politikat-e-privatesise-social4 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.politikat-e-privatesise-social4:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.politikat-e-privatesise-icon24 {
  width: 18px;
  height: 18px;
}
 
.politikat-e-privatesise-links2 {
  gap: var(--dl-layout-space-fiveunits);
  display: flex;
  z-index: 15;
  align-items: flex-start;
  flex-direction: row;
}
 
.politikat-e-privatesise-column1 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.politikat-e-privatesise-header2 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-layout-space-oneandhalfunits);
}
 
.politikat-e-privatesise-link20 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.politikat-e-privatesise-link20:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.politikat-e-privatesise-link21 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.politikat-e-privatesise-link21:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.politikat-e-privatesise-link22 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.politikat-e-privatesise-link22:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.politikat-e-privatesise-link23 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.politikat-e-privatesise-link23:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.politikat-e-privatesise-link24 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.politikat-e-privatesise-link24:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.politikat-e-privatesise-column2 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.politikat-e-privatesise-header3 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-layout-space-oneandhalfunits);
}
 
.politikat-e-privatesise-link25 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.politikat-e-privatesise-link25:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.politikat-e-privatesise-link26 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.politikat-e-privatesise-link26:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.politikat-e-privatesise-pills {
  width: 100%;
  height: 77px;
  display: flex;
  grid-gap: var(--dl-layout-space-twounits);
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: center;
  margin-left: 0px;
  margin-bottom: 0px;
  flex-direction: row;
}
 
.politikat-e-privatesise-container4 {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
 
.politikat-e-privatesise-you-tube1 {
  width: 154px;
  height: 79px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
 
.politikat-e-privatesise-link27 {
  display: contents;
}
 
.politikat-e-privatesise-pasted-image2 {
  width: 533px;
  height: 80px;
  text-decoration: none;
}
 
.politikat-e-privatesise-you-tube2 {
  width: 125px;
  height: 79px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  border-radius: 24px;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
 
.politikat-e-privatesise-link28 {
  display: contents;
}
 
.politikat-e-privatesise-pasted-image3 {
  width: 350px;
  height: 80px;
  display: grid;
  text-decoration: none;
}
 
.politikat-e-privatesise-text368 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  max-width: 1200px;
  line-height: 21px;
}
 
@media(max-width: 1600px) {
  .politikat-e-privatesise-logo {
    margin-left: var(--dl-layout-space-threeunits);
  }
  .politikat-e-privatesise-links1 {
    margin-right: var(--dl-layout-space-threeunits);
  }
  .politikat-e-privatesise-link11 {
    font-size: 25px;
  }
  .politikat-e-privatesise-link12 {
    font-size: 25px;
  }
  .politikat-e-privatesise-link13 {
    font-size: 25px;
  }
  .politikat-e-privatesise-link14 {
    font-size: 25px;
    font-family: Poppins;
  }
  .politikat-e-privatesise-container3 {
    padding-left: 140px;
    padding-right: 140px;
  }
  .politikat-e-privatesise-heading1 {
    font-size: 40px;
  }
  .politikat-e-privatesise-column1 {
    display: grid;
  }
  .politikat-e-privatesise-pills {
    margin-right: var(--dl-layout-space-threeunits);
  }
  .politikat-e-privatesise-you-tube1 {
    width: 209px;
    height: 77px;
  }
}
 
@media(max-width: 1200px) {
  .politikat-e-privatesise-link11 {
    text-align: center;
  }
  .politikat-e-privatesise-link12 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .politikat-e-privatesise-link13 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
  }
  .politikat-e-privatesise-link14 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
  }
}
 
@media(max-width: 991px) {
  .politikat-e-privatesise-navbar {
    z-index: 1000;
  }
  .politikat-e-privatesise-right {
    display: none;
  }
  .politikat-e-privatesise-burger-menu {
    display: flex;
    padding: var(--dl-layout-space-unit);
    margin-right: var(--dl-layout-space-threeunits);
    border-radius: var(--dl-layout-radius-round);
    background-color: #48787B;
  }
  .politikat-e-privatesise-icon10 {
    fill: white;
  }
  .politikat-e-privatesise-links1 {
    display: none;
  }
  .politikat-e-privatesise-footer {
    padding-top: var(--dl-layout-space-fourunits);
    padding-left: var(--dl-layout-space-threeunits);
    padding-right: var(--dl-layout-space-threeunits);
    padding-bottom: var(--dl-layout-space-fourunits);
  }
  .politikat-e-privatesise-pills {
    align-self: center;
  }
}
 
@media(max-width: 767px) {
  .politikat-e-privatesise-navbar {
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-unit);
  }
  .politikat-e-privatesise-logo {
    margin-left: var(--dl-layout-space-twounits);
  }
  .politikat-e-privatesise-burger-menu {
    display: flex;
  }
  .politikat-e-privatesise-image {
    width: 76px;
  }
  .politikat-e-privatesise-container3 {
    padding-left: 100px;
    padding-right: 100px;
  }
  .politikat-e-privatesise-content65 {
    font-style: normal;
    font-weight: 400;
    line-height: 1.15;
  }
  .politikat-e-privatesise-footer {
    gap: var(--dl-layout-space-twounits);
  }
  .politikat-e-privatesise-content8 {
    gap: var(--dl-layout-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .politikat-e-privatesise-information {
    gap: var(--dl-layout-space-oneandhalfunits);
  }
  .politikat-e-privatesise-heading {
    gap: var(--dl-layout-space-halfunit);
  }
  .politikat-e-privatesise-pasted-image1 {
    width: 100px;
  }
  .politikat-e-privatesise-text364 {
    font-size: 12px;
    line-height: 18px;
  }
  .politikat-e-privatesise-socials {
    gap: var(--dl-layout-space-halfunit);
  }
  .politikat-e-privatesise-social1 {
    padding: 12px;
  }
  .politikat-e-privatesise-social2 {
    padding: 12px;
  }
  .politikat-e-privatesise-social3 {
    padding: 12px;
  }
  .politikat-e-privatesise-social4 {
    padding: 12px;
  }
  .politikat-e-privatesise-links2 {
    gap: var(--dl-layout-space-oneandhalfunits);
    width: 100%;
    flex-direction: column;
  }
  .politikat-e-privatesise-header2 {
    font-size: 16px;
    line-height: 24px;
  }
  .politikat-e-privatesise-header3 {
    font-size: 16px;
    line-height: 24px;
  }
  .politikat-e-privatesise-pills {
    align-self: flex-end;
    margin-top: var(--dl-layout-space-threeunits);
    margin-left: 0%;
    margin-right: 0px;
    margin-bottom: var(--dl-layout-space-threeunits);
    justify-content: flex-start;
  }
  .politikat-e-privatesise-container4 {
    align-items: flex-start;
    justify-content: center;
  }
}
 
@media(max-width: 479px) {
  .politikat-e-privatesise-navbar {
    padding: var(--dl-layout-space-unit);
  }
  .politikat-e-privatesise-logo {
    width: 83px;
    height: 35px;
    margin-left: 0px;
  }
  .politikat-e-privatesise-burger-menu {
    margin-right: 0px;
  }
  .politikat-e-privatesise-mobile-menu {
    padding: 16px;
  }
  .politikat-e-privatesise-nav1 {
    height: 725px;
  }
  .politikat-e-privatesise-container2 {
    height: 155px;
    align-self: center;
    margin-bottom: 0px;
    justify-content: space-between;
  }
  .politikat-e-privatesise-image {
    width: 100px;
    height: 100px;
    align-self: center;
    margin-left: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .politikat-e-privatesise-icon12 {
    width: 28px;
    height: 28px;
  }
  .politikat-e-privatesise-nav2 {
    align-self: flex-start;
    margin-left: var(--dl-layout-space-halfunit);
  }
  .politikat-e-privatesise-navlink3 {
    font-size: 25px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: var(--dl-layout-space-twounits);
    text-decoration: none;
  }
  .politikat-e-privatesise-navlink4 {
    font-size: 25px;
    align-self: flex-start;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: var(--dl-layout-space-twounits);
    text-decoration: none;
  }
  .politikat-e-privatesise-link10 {
    font-size: 25px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: var(--dl-layout-space-twounits);
    text-decoration: none;
  }
  .politikat-e-privatesise-navlink5 {
    font-size: 25px;
    align-self: flex-start;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    text-decoration: none;
  }
  .politikat-e-privatesise-container3 {
    padding-left: var(--dl-layout-space-threeunits);
    padding-right: var(--dl-layout-space-threeunits);
  }
  .politikat-e-privatesise-content65 {
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.15;
  }
  .politikat-e-privatesise-content69 {
    font-family: Poppins;
  }
  .politikat-e-privatesise-text359 {
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.15;
  }
  .politikat-e-privatesise-text362 {
    font-size: 18px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.15;
  }
  .politikat-e-privatesise-content8 {
    align-self: center;
  }
  .politikat-e-privatesise-information {
    align-self: center;
  }
  .politikat-e-privatesise-heading {
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .politikat-e-privatesise-pasted-image1 {
    align-self: center;
  }
  .politikat-e-privatesise-text364 {
    color: #c4c4c4;
    font-size: 20px;
    align-self: center;
    text-align: center;
    line-height: 18px;
  }
  .politikat-e-privatesise-socials {
    align-self: center;
    justify-content: center;
  }
  .politikat-e-privatesise-links2 {
    width: 100%;
    align-self: center;
    flex-direction: column;
  }
  .politikat-e-privatesise-column1 {
    align-self: center;
    place-items: center;
  }
  .politikat-e-privatesise-header2 {
    font-size: 24px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .politikat-e-privatesise-link20 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .politikat-e-privatesise-link21 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .politikat-e-privatesise-link22 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .politikat-e-privatesise-link23 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    line-height: 21px;
  }
  .politikat-e-privatesise-link24 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    line-height: 21px;
  }
  .politikat-e-privatesise-column2 {
    align-self: center;
  }
  .politikat-e-privatesise-header3 {
    font-size: 24px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .politikat-e-privatesise-link25 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    font-family: Poppins;
    line-height: 21px;
  }
  .politikat-e-privatesise-link26 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    text-align: center;
    font-family: Poppins;
    line-height: 21px;
  }
  .politikat-e-privatesise-pills {
    align-self: center;
    margin-top: var(--dl-layout-space-eightunits);
    align-items: stretch;
    margin-left: 0%;
    margin-bottom: var(--dl-layout-space-eightunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .politikat-e-privatesise-you-tube1 {
    width: 120px;
    height: 60px;
    align-items: center;
    border-radius: 100px;
  }
  .politikat-e-privatesise-pasted-image2 {
    width: var(--dl-layout-size-xlarge);
    height: auto;
  }
  .politikat-e-privatesise-you-tube2 {
    width: 120px;
    height: 60px;
    align-items: center;
    border-radius: 100px;
  }
  .politikat-e-privatesise-pasted-image3 {
    width: var(--dl-layout-size-xlarge);
    height: auto;
  }
  .politikat-e-privatesise-text368 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    text-align: center;
    font-family: Poppins;
    line-height: 21px;
  }
}
