.news-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
 
.news-header1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
 
.news-navbar {
  width: 1776px;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: center;
  justify-content: space-between;
}
 
.news-left {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.news-navlink1 {
  display: contents;
}
 
.news-logo {
  width: var(--dl-layout-size-large);
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
 
.news-right {
  gap: var(--dl-layout-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
 
.news-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
 
.news-icon10 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
.news-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  transform: translateY(-100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
 
.news-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.news-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-layout-space-threeunits);
  justify-content: space-between;
}
 
.news-navlink2 {
  display: contents;
}
 
.news-image1 {
  width: 78px;
  height: 67px;
  text-decoration: none;
}
 
.news-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.news-icon12 {
  width: var(--dl-layout-size-xsmall);
  height: var(--dl-layout-size-xsmall);
}
 
.news-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.news-navlink3 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.news-navlink4 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.news-link10 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  margin-bottom: var(--dl-layout-space-unit);
  text-decoration: none;
}
 
.news-navlink5 {
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.news-links1 {
  gap: var(--dl-layout-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}
 
.news-link11 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.news-link11:hover {
  opacity: 0.5;
}
 
.news-link12 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  text-decoration: none;
}
 
.news-link12:hover {
  opacity: 0.5;
}
 
.news-link13 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.news-link13:hover {
  opacity: 0.5;
}
 
.news-link14 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.news-link14:hover {
  opacity: 0.5;
}
 
.news-link15 {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
  font-size: 25px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
}
 
.news-link15:hover {
  opacity: 0.5;
}
 
.news-why {
  gap: var(--dl-layout-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  padding-top: var(--dl-layout-space-fiveunits);
  padding-left: var(--dl-layout-space-fiveunits);
  padding-right: var(--dl-layout-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
 
.news-header2 {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.news-heading1 {
  gap: var(--dl-layout-space-oneandhalfunits);
  display: flex;
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.news-title {
  width: 853px;
  display: flex;
  font-size: 40px;
  align-self: center;
  font-style: normal;
  text-align: center;
  align-items: stretch;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 40px;
  justify-content: flex-start;
}
 
.news-brands {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.news-row {
  gap: var(--dl-layout-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
 
.news-item {
  gap: var(--dl-layout-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
 
.news-image2 {
  width: 100%;
  height: var(--dl-layout-size-xxlarge);
  align-self: center;
  object-fit: cover;
  border-radius: var(--dl-layout-radius-cardradius);
}
 
.news-details {
  gap: var(--dl-layout-space-oneandhalfunits);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
 
.news-description {
  font-size: 18px;
  font-family: "Poppins";
  line-height: 27px;
}
 
.news-text10 {
  font-style: normal;
  font-weight: 600;
}
 
.news-text11 {
  font-style: normal;
  font-weight: 600;
}
 
.news-text16 {
  font-style: normal;
  font-weight: 600;
}
 
.news-text22 {
  font-weight: 600;
}
 
.news-footer {
  gap: var(--dl-layout-space-threeunits);
  color: white;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-layout-space-sixunits);
  padding-left: var(--dl-layout-space-fiveunits);
  padding-right: var(--dl-layout-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-sixunits);
  background-color: #1B1D21;
}
 
.news-content {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
 
.news-information {
  gap: var(--dl-layout-space-unit);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.news-heading2 {
  gap: var(--dl-layout-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
 
.news-link16 {
  display: contents;
}
 
.news-pasted-image1 {
  width: var(--dl-layout-size-large);
  text-decoration: none;
}
 
.news-text27 {
  font-size: 20px;
  font-family: Poppins;
  line-height: 21px;
}
 
.news-socials {
  gap: var(--dl-layout-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  max-width: 265px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
 
.news-link17 {
  display: contents;
}
 
.news-social1 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.news-social1:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.news-icon14 {
  width: 18px;
  height: 18px;
}
 
.news-link18 {
  display: contents;
}
 
.news-social2 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  padding-bottom: 16px;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.news-social2:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.news-icon18 {
  width: 18px;
  height: 18px;
}
 
.news-link19 {
  display: contents;
}
 
.news-social3 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.news-social3:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.news-icon20 {
  width: 18px;
  height: 18px;
}
 
.news-link20 {
  display: contents;
}
 
.news-social4 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-layout-space-unit);
  position: relative;
  transition: 0.3s;
  align-items: flex-start;
  border-radius: var(--dl-layout-radius-radius8);
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  background-color: rgba(236, 233, 234, 0.2);
}
 
.news-social4:hover {
  background-color: rgba(236, 233, 234, 0.6);
}
 
.news-icon24 {
  width: 18px;
  height: 18px;
}
 
.news-links2 {
  gap: var(--dl-layout-space-fiveunits);
  display: flex;
  z-index: 15;
  align-items: flex-start;
  flex-direction: row;
}
 
.news-column1 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.news-header3 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-layout-space-oneandhalfunits);
}
 
.news-link21 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.news-link21:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.news-link22 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.news-link22:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.news-link23 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.news-link23:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.news-link24 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.news-link24:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.news-link25 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.news-link25:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.news-column2 {
  gap: var(--dl-layout-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
 
.news-header4 {
  font-size: 24px;
  font-style: normal;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 30px;
  padding-top: var(--dl-layout-space-oneandhalfunits);
}
 
.news-link26 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.news-link26:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.news-link27 {
  color: rgb(196, 196, 196);
  font-size: 20px;
  font-family: "Poppins";
  line-height: 21px;
  text-decoration: none;
}
 
.news-link27:hover {
  color: rgba(196, 196, 196, 0.5);
}
 
.news-pills {
  width: 100%;
  height: 77px;
  display: flex;
  grid-gap: var(--dl-layout-space-twounits);
  flex-wrap: wrap;
  max-width: 1200px;
  align-items: center;
  margin-left: 0px;
  margin-bottom: 0px;
  flex-direction: row;
}
 
.news-container3 {
  gap: var(--dl-layout-space-twounits);
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
 
.news-you-tube1 {
  width: 154px;
  height: 79px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  border-radius: 24px;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
 
.news-link28 {
  display: contents;
}
 
.news-pasted-image2 {
  width: 533px;
  height: 80px;
  text-decoration: none;
}
 
.news-you-tube2 {
  width: 125px;
  height: 79px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  border-radius: 24px;
  flex-direction: row;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
 
.news-link29 {
  display: contents;
}
 
.news-pasted-image3 {
  width: 350px;
  height: 80px;
  display: grid;
  text-decoration: none;
}
 
.news-text31 {
  color: #C4C4C4;
  width: 100%;
  font-size: 14px;
  max-width: 1200px;
  line-height: 21px;
}
 
@media(max-width: 1600px) {
  .news-logo {
    margin-left: var(--dl-layout-space-threeunits);
  }
  .news-links1 {
    margin-right: var(--dl-layout-space-threeunits);
  }
  .news-link11 {
    font-size: 25px;
  }
  .news-link12 {
    font-size: 25px;
  }
  .news-link13 {
    font-size: 25px;
  }
  .news-link14 {
    font-size: 25px;
  }
  .news-link15 {
    font-size: 25px;
    font-family: Poppins;
  }
  .news-why {
    max-width: 1000px;
    padding-bottom: var(--dl-layout-space-fiveunits);
  }
  .news-title {
    align-self: flex-start;
    text-align: center;
  }
  .news-description {
    font-family: Poppins;
    line-height: 27px;
  }
  .news-column1 {
    display: grid;
  }
  .news-pills {
    margin-right: var(--dl-layout-space-threeunits);
  }
  .news-you-tube1 {
    width: 209px;
    height: 77px;
  }
}
 
@media(max-width: 1200px) {
  .news-link11 {
    text-align: center;
  }
  .news-link12 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
  }
  .news-link13 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 25px;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
    text-decoration: none;
  }
  .news-link14 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
    font-size: 25px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .news-link15 {
    fill: var(--dl-color-gray-black);
    color: var(--dl-color-gray-black);
  }
  .news-why {
    max-width: auto;
  }
  .news-title {
    text-align: center;
  }
}
 
@media(max-width: 991px) {
  .news-navbar {
    z-index: 1000;
  }
  .news-right {
    display: none;
  }
  .news-burger-menu {
    display: flex;
    padding: var(--dl-layout-space-unit);
    margin-right: var(--dl-layout-space-threeunits);
    border-radius: var(--dl-layout-radius-round);
    background-color: #48787B;
  }
  .news-icon10 {
    fill: white;
  }
  .news-links1 {
    display: none;
  }
  .news-header2 {
    align-self: center;
  }
  .news-title {
    width: auto;
    align-self: center;
  }
  .news-footer {
    padding-top: var(--dl-layout-space-fourunits);
    padding-left: var(--dl-layout-space-threeunits);
    padding-right: var(--dl-layout-space-threeunits);
    padding-bottom: var(--dl-layout-space-fourunits);
  }
  .news-pills {
    align-self: center;
  }
}
 
@media(max-width: 767px) {
  .news-navbar {
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-unit);
  }
  .news-logo {
    margin-left: var(--dl-layout-space-twounits);
  }
  .news-burger-menu {
    display: flex;
  }
  .news-image1 {
    width: 76px;
  }
  .news-why {
    padding-left: var(--dl-layout-space-oneandhalfunits);
    padding-right: var(--dl-layout-space-oneandhalfunits);
    padding-bottom: var(--dl-layout-space-threeunits);
  }
  .news-heading1 {
    gap: var(--dl-layout-space-unit);
  }
  .news-title {
    width: 100%;
    font-size: 30px;
    align-self: center;
    line-height: 33px;
    justify-content: center;
  }
  .news-row {
    gap: var(--dl-layout-space-twounits);
    flex-direction: column;
  }
  .news-item {
    gap: var(--dl-layout-space-oneandhalfunits);
  }
  .news-image2 {
    height: 60px;
  }
  .news-details {
    gap: var(--dl-layout-space-unit);
  }
  .news-description {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text11 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text12 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text14 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text15 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text17 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text18 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text20 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text21 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text23 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text24 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-text26 {
    width: 90%;
    font-size: 16px;
    line-height: 24px;
  }
  .news-footer {
    gap: var(--dl-layout-space-twounits);
  }
  .news-content {
    gap: var(--dl-layout-space-threeunits);
    flex-direction: column;
    justify-content: flex-start;
  }
  .news-information {
    gap: var(--dl-layout-space-oneandhalfunits);
  }
  .news-heading2 {
    gap: var(--dl-layout-space-halfunit);
  }
  .news-pasted-image1 {
    width: 100px;
  }
  .news-text27 {
    font-size: 12px;
    line-height: 18px;
  }
  .news-socials {
    gap: var(--dl-layout-space-halfunit);
  }
  .news-social1 {
    padding: 12px;
  }
  .news-social2 {
    padding: 12px;
  }
  .news-social3 {
    padding: 12px;
  }
  .news-social4 {
    padding: 12px;
  }
  .news-links2 {
    gap: var(--dl-layout-space-oneandhalfunits);
    width: 100%;
    flex-direction: column;
  }
  .news-header3 {
    font-size: 16px;
    line-height: 24px;
  }
  .news-header4 {
    font-size: 16px;
    line-height: 24px;
  }
  .news-pills {
    align-self: flex-end;
    margin-top: var(--dl-layout-space-threeunits);
    margin-left: 0%;
    margin-right: 0px;
    margin-bottom: var(--dl-layout-space-threeunits);
    justify-content: flex-start;
  }
  .news-container3 {
    align-items: flex-start;
    justify-content: center;
  }
}
 
@media(max-width: 479px) {
  .news-navbar {
    padding: var(--dl-layout-space-unit);
  }
  .news-logo {
    width: 83px;
    height: 35px;
    margin-left: 0px;
  }
  .news-burger-menu {
    margin-right: 0px;
  }
  .news-mobile-menu {
    padding: 16px;
  }
  .news-nav1 {
    height: 725px;
  }
  .news-container2 {
    height: 155px;
    align-self: center;
    margin-bottom: 0px;
    justify-content: space-between;
  }
  .news-image1 {
    width: 100px;
    height: 100px;
    align-self: center;
    margin-left: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
  .news-icon12 {
    width: 25px;
    height: 25px;
  }
  .news-nav2 {
    align-self: flex-start;
    margin-left: var(--dl-layout-space-halfunit);
  }
  .news-navlink3 {
    font-size: 25px;
    align-self: flex-start;
    font-style: normal;
    text-align: center;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: var(--dl-layout-space-twounits);
    text-decoration: none;
  }
  .news-navlink4 {
    font-size: 25px;
    align-self: flex-start;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: var(--dl-layout-space-twounits);
    text-decoration: none;
  }
  .news-link10 {
    font-size: 25px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    margin-bottom: var(--dl-layout-space-twounits);
    text-decoration: none;
  }
  .news-navlink5 {
    font-size: 25px;
    align-self: flex-start;
    font-style: normal;
    font-family: Poppins;
    font-weight: 400;
    text-decoration: none;
  }
  .news-title {
    width: 100%;
  }
  .news-item {
    width: 100%;
    max-width: auto;
    align-self: center;
  }
  .news-image2 {
    width: 100%;
    height: 100%;
    align-self: flex-start;
  }
  .news-description {
    align-self: center;
  }
  .news-content {
    align-self: center;
  }
  .news-information {
    align-self: center;
  }
  .news-heading2 {
    max-width: 100%;
    align-items: center;
    justify-content: flex-start;
  }
  .news-pasted-image1 {
    align-self: center;
  }
  .news-text27 {
    color: #c4c4c4;
    font-size: 20px;
    align-self: center;
    text-align: center;
    line-height: 18px;
  }
  .news-socials {
    align-self: center;
    justify-content: center;
  }
  .news-links2 {
    width: 100%;
    align-self: center;
    flex-direction: column;
  }
  .news-column1 {
    align-self: center;
    place-items: center;
  }
  .news-header3 {
    font-size: 24px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .news-link21 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .news-link22 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .news-link23 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    line-height: 21px;
  }
  .news-link24 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    line-height: 21px;
  }
  .news-link25 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    line-height: 21px;
  }
  .news-column2 {
    align-self: center;
  }
  .news-header4 {
    font-size: 24px;
    align-self: center;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
    line-height: 24px;
  }
  .news-link26 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    align-self: center;
    font-family: Poppins;
    line-height: 21px;
  }
  .news-link27 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    text-align: center;
    font-family: Poppins;
    line-height: 21px;
  }
  .news-pills {
    align-self: center;
    margin-top: var(--dl-layout-space-eightunits);
    align-items: stretch;
    margin-left: 0%;
    margin-bottom: var(--dl-layout-space-eightunits);
    flex-direction: column;
    justify-content: space-between;
  }
  .news-you-tube1 {
    width: 120px;
    height: 60px;
    align-items: center;
    border-radius: 100px;
  }
  .news-pasted-image2 {
    width: var(--dl-layout-size-xlarge);
    height: auto;
  }
  .news-you-tube2 {
    width: 120px;
    height: 60px;
    align-items: center;
    border-radius: 100px;
  }
  .news-pasted-image3 {
    width: var(--dl-layout-size-xlarge);
    height: auto;
  }
  .news-text31 {
    color: rgb(196, 196, 196);
    font-size: 20px;
    text-align: center;
    font-family: Poppins;
    line-height: 21px;
  }
}
