.kontakt-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #286263;
}
 
.kontakt-contact {
  display: flex;
  margin-top: var(--dl-layout-space-sevenunits);
  align-items: center;
  margin-bottom: var(--dl-layout-space-sevenunits);
  flex-direction: column;
}
 
.kontakt-form {
  flex: 0 0 auto;
  width: 100%;
  height: 387px;
  display: flex;
  max-width: 700px;
  margin-top: 0px;
  align-items: flex-start;
  margin-left: 0px;
  padding-top: var(--dl-layout-space-twoandhalf);
  margin-right: 0px;
  padding-left: var(--dl-layout-space-twoandhalf);
  border-radius: var(--dl-layout-radius-inputradius);
  margin-bottom: 0px;
  padding-right: var(--dl-layout-space-twoandhalf);
  flex-direction: column;
  padding-bottom: var(--dl-layout-space-twoandhalf);
  background-color: var(--dl-color-scheme-white);
}
 
.kontakt-text {
  fill: var(--dl-color-theme-neutral-dark);
  color: var(--dl-color-theme-neutral-dark);
  font-size: 28px;
  align-self: center;
  font-family: "Poppins";
}
 
.kontakt-container2 {
  gap: var(--dl-layout-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-layout-space-twounits);
  align-items: flex-start;
  flex-direction: row;
}
 
.kontakt-input1 {
  gap: var(--dl-layout-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.kontakt-text-input1 {
  fill: rgba(148, 163, 184, 1);
  color: rgba(148, 163, 184, 1);
  border-color: #ADDFBC;
  border-radius: var(--dl-layout-radius-radius4);
  background-color: var(--dl-color-scheme-white);
}
 
.kontakt-input2 {
  gap: var(--dl-layout-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.kontakt-text-input2 {
  fill: rgba(148, 163, 184, 1);
  color: rgba(148, 163, 184, 1);
  border-color: #ADDFBC;
  border-radius: var(--dl-layout-radius-radius4);
  background-color: var(--dl-color-scheme-white);
}
 
.kontakt-container3 {
  gap: var(--dl-layout-space-unit);
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  margin-top: var(--dl-layout-space-unit);
  align-items: flex-start;
  flex-direction: row;
}
 
.kontakt-input3 {
  gap: var(--dl-layout-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.kontakt-text-input3 {
  fill: rgba(148, 163, 184, 1);
  color: rgba(148, 163, 184, 1);
  box-shadow: 0px 2px 0px 0px undefined,0px 3px 0px 0px #000000;
  border-color: #ADDFBC;
  border-radius: var(--dl-layout-radius-radius4);
  background-color: var(--dl-color-scheme-white);
}
 
.kontakt-input4 {
  gap: var(--dl-layout-space-halfunit);
  width: 50%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
 
.kontakt-text-input4 {
  fill: rgba(148, 163, 184, 1);
  color: rgba(148, 163, 184, 1);
  border-color: #ADDFBC;
  border-radius: var(--dl-layout-radius-radius4);
  background-color: var(--dl-color-scheme-white);
}
 
.kontakt-textarea {
  color: rgba(148, 163, 184, 1);
  width: 100%;
  outline: 0;
  padding: var(--dl-layout-space-triplequarter);
  font-size: 0.87rem;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1),0 1px 2px 0 rgba(0,0,0,0.06);;
  margin-top: var(--dl-layout-space-unit);
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 1.25;
  border-color: #ADDFBC;
  border-width: 1px;
  border-radius: var(--dl-layout-radius-radius4);
  text-decoration: none;
}
 
.kontakt-button {
  color: white;
  cursor: pointer;
  font-size: 18px;
  align-self: center;
  font-style: normal;
  margin-top: 40px;
  transition: 0.3s;
  font-family: "Poppins";
  font-weight: 600;
  padding-top: var(--dl-layout-space-unit);
  border-width: 0px;
  padding-left: var(--dl-layout-space-twounits);
  border-radius: 56px;
  padding-right: var(--dl-layout-space-twounits);
  padding-bottom: var(--dl-layout-space-unit);
  background-color: var(--dl-color-gray-black);
}
 
.kontakt-button:hover {
  fill: var(--dl-color-scheme-white);
  color: var(--dl-color-scheme-white);
  background-color: #286263;
}
 
@media(max-width: 1600px) {
  .kontakt-text-input1 {
    fill: rgba(148, 163, 184, 1);
    color: rgba(148, 163, 184, 1);
  }
  .kontakt-text-input3 {
    fill: rgba(148, 163, 184, 1);
    color: rgba(148, 163, 184, 1);
  }
  .kontakt-text-input4 {
    fill: rgba(148, 163, 184, 1);
    color: rgba(148, 163, 184, 1);
  }
  .kontakt-textarea {
    height: auto;
  }
}
 
@media(max-width: 1200px) {
  .kontakt-contact {
    margin-top: var(--dl-layout-space-eightunits);
    margin-bottom: var(--dl-layout-space-eightunits);
  }
}
 
@media(max-width: 991px) {
  .kontakt-container2 {
    gap: var(--dl-layout-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .kontakt-input1 {
    width: 50%;
  }
  .kontakt-input2 {
    width: 50%;
    flex-direction: column;
  }
  .kontakt-container3 {
    gap: var(--dl-layout-space-twounits);
    width: 100%;
    flex-direction: row;
  }
  .kontakt-input3 {
    width: 50%;
  }
  .kontakt-input4 {
    width: 50%;
  }
}
 
@media(max-width: 767px) {
  .kontakt-contact {
    margin-top: var(--dl-layout-space-sevenunits);
  }
}
 
@media(max-width: 479px) {
  .kontakt-contact {
    width: auto;
    height: auto;
  }
  .kontakt-form {
    height: auto;
  }
  .kontakt-text {
    text-align: left;
  }
  .kontakt-container2 {
    align-items: stretch;
    flex-direction: column;
  }
  .kontakt-input1 {
    width: 100%;
  }
  .kontakt-input2 {
    width: 100%;
  }
  .kontakt-container3 {
    align-items: stretch;
    flex-direction: column;
  }
  .kontakt-input3 {
    width: 100%;
  }
  .kontakt-input4 {
    width: 100%;
  }
}
